import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";

import MovieCarousel from "../../../../components/MovieCarousel";
import MovieHero from "../../../../components/MovieHero";
import ViewNext from "../../../../components/ViewNext";
import MovieList from "../../../../components/MovieList";
import Container from "../../../../styles/Container";
import HelmetInfo from "../../../../components/HelmetInfo";

const Line = styled.div`
  width: 100%;
  border-top: 7px solid rgba(155, 155, 155, 0.218835);
`;

const WhatsNew = ({ data }) => {
  let newMovies = undefined;
  if (Array.isArray(data.allPrismicMovie.edges)) {
    newMovies = data.allPrismicMovie.edges.filter((movie) => {
      if (movie.node.data.new_movie === "Yes") {
        return true;
      } else {
        return false;
      }
    });
  }

  let movies = undefined;
  if (Array.isArray(newMovies)) {
    movies = newMovies.map((movie) => {
      return { document: movie.node };
    });
  }

  return (
    <>
      <HelmetInfo page_data={data.prismicInternationalTitles.data} title_override={"International Titles - What's New"} />
      <MovieCarousel items={data.prismicInternationalTitles.data.carousel} />
      <MovieHero heading="What’s new" />
      <Container>
        <Line />
      </Container>
      <MovieList heading="What’s new" items={movies} hasSearch />
      <ViewNext
        text="View Next Page"
        link={`/distribution/screen-media/us-syndication`}
        linkText="US Syndication"
        borderTop
        whiteBackground
      />
    </>
  );
};

export default WhatsNew;

export const query = graphql`
  query WhatsNewQuery {
    prismicInternationalTitles {
      data {
        carousel {
          image {
            url
            alt
          }
          button_text
          button_link {
            link_type
            uid
            type
            url
          }
          image_heading
        }
      }
    }
    allPrismicMovie(sort: { fields: data___title }) {
      totalCount
      edges {
        node {
          ... on PrismicMovie {
            type
            uid
            data {
              movie_poster {
                url
                alt
              }
              title
              info
              genre
              new_movie
            }
          }
        }
      }
    }
  }
`;
